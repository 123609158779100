import _ from 'lodash'

export const DEXLAB_MARKETS = [
  {
    address: 'HFZtxFAKcrEwjgUTrmCHhHWbVtDAWMV491QLQkXADBRq',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'DXL',
    baseMint: 'GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'DXL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'ETC',
    nameEn: 'Dexlab',
    nameCn: null,
    nameKr: '덱스랩',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/dxl/symbol.png?raw=true',
    nftImageUrl: null,
    community: [
      {
        url: 'https://t.me/dexlab_official',
        name: 'Telegram',
      },
      {
        url: 'https://dexlab-space.medium.com/',
        name: 'Medium',
      },
      {
        url: 'https://discord.gg/XcQDV7z2Hz',
        name: 'Discord',
      },
      {
        url: 'https://www.dexlab.space/whitepaper/DexLab_White_paper_1_1_eng.pdf',
        name: 'White Paper',
      },
      {
        url: 'https://docs.dexlab.space',
        name: 'Document',
      },
      {
        url: 'https://www.dexlab.space',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/Dexlab_official',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'dexlab',
    tvWidgetChartSymbol: null,
    isDexlabChart: true,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SOL',
    baseMint: 'So11111111111111111111111111111111111111112',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Solana',
    nameCn: null,
    nameKr: '솔라나',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/sol/symbol.png?raw=true',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/solana',
        name: 'TWITTER',
      },
      {
        url: 'https://solana.com',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://github.com/solana-labs/',
        name: 'GitHub',
      },
      {
        url: 'https://docs.solana.com/',
        name: 'Document',
      },
      {
        url: 'https://discord.com/invite/pquxPsq',
        name: 'Discord',
      },
      {
        url: 'https://t.me/solana',
        name: 'Telegram',
      },
    ],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'solana',
    tvWidgetChartSymbol: 'SOLUSDT',
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 99,
    enable: true,
  },
  {
    address: 'FZxi3yWkE5mMjyaZj6utmYL54QQYfMCKMcLaQZq4UwnA',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'ETH',
    baseMint: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'ETH/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Ethereum',
    nameCn: null,
    nameKr: '이더리움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/eth/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'ethereum',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 98,
    enable: true,
  },
  {
    address: 'DZjbn4XC8qoHKikZqzmhemykVzmossoayV9ffbsUqxVj',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RAY',
    baseMint: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'RAY/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Raydium',
    nameCn: null,
    nameKr: '레이디움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/ray/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'raydium',
    tvWidgetChartSymbol: 'RAYUSDT',
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: 'B2na8Awyd7cpC59iEU43FagJAPLigr3AP3s38KM982bu',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'USDT',
    baseMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'USDT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Tether',
    nameCn: null,
    nameKr: '테더',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    nftImageUrl: null,
    community: [],
    chartType: 'KUCOIN',
    orderType: 'GENERAL',
    coingeckoId: 'tether',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 97,
    enable: true,
  },
  {
    address: '3NnxQvDcZXputNMxaxsGvqiKpqgPfSYXpNigZNFcknmD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MNGO',
    baseMint: 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'MNGO/USDC',
    mainCategory: 'DEX',
    subCategory: 'FINANCE',
    nameEn: 'Mango',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
    nftImageUrl: null,
    community: [
      {
        url: 'http://mango.markets',
        name: 'HOMEPAGE',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'mango-markets',
    tvWidgetChartSymbol: 'GATEIO:MNGOUSDT',
    isDexlabChart: true,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 96,
    enable: true,
  },
  {
    address: '9Lyhks5bQQxb9EyyX55NtgKQzpM4WK7JCmeaWuQ5MoXD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MSOL',
    baseMint: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MSOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'DEFI',
    nameEn: 'Marinade staked SOL',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://assets.coingecko.com/coins/images/17752/small/PJ7Scn3.png?1629175951',
    nftImageUrl: null,
    community: [
      {
        url: 'https://marinade.finance',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/MarinadeFinance',
        name: 'TWITTER',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'marinade-staked-sol',
    tvWidgetChartSymbol: 'GATEIO:MSOLUSDT',
    isDexlabChart: true,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 95,
    enable: true,
  },
  {
    address: '72h8rWaWwfPUL36PAFqyQZU8RT1V3FKG7Nc45aK89xTs',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RLB',
    baseMint: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'RLB/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Rollbit Coin',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://rollbit.com/',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/rollbitcom',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'rollbit-coin',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 94,
    enable: true,
  },
  {
    address: 'CQyhWBaaVRgtLfRegX94JjMxixxKun4Eq3r7wbDSW7LZ',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'LSTAR',
    baseMint: 'C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'LSTAR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Learning Star',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3yigvdWq6qobhAwQL6UiSide5W9W7zcJbuVvKa3T231/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://www.letmespeak.org',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/Letmespeak_org',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'learning-star',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '5xCudgPvVetTL3M4ExtPuAFTnCr2hdQ3cte9LhGAuBee',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'ARB',
    baseMint: '9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'ARB/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'ARB Protocol',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://raw.githubusercontent.com/ARBProtocol/arbprotocol/main/IMG_3600.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/arbprotocol',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'arb-protocol',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '8PhnCfgqpgFM7ZJvttGdBVMXHuU4Q23ACxCvWkbs1M71',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BONK',
    baseMint: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 6,
    symbol: 'BONK/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Bonk',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/bonk_inu',
        name: 'TWITTER',
      },
    ],
    chartType: 'HUOBI',
    orderType: 'GENERAL',
    coingeckoId: 'bonk',
    tvWidgetChartSymbol: 'HUOBI:BONKUSDT',
    isDexlabChart: true,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 93,
    enable: true,
  },
  {
    address: 'CFW3YFB4uN1NWFDYmcKmT9qA3iyi3L5Bz9QJNjKQAWeS',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'PRT',
    baseMint: 'PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'PRT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Parrot Protocol',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/gopartyparrot',
        name: 'TWITTER',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'parrot-protocol',
    tvWidgetChartSymbol: 'GATEIO:PRTUSDT',
    isDexlabChart: true,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '4JGMRnbJY6cLTwptAtCiP7YotNWkHJcriJKfwM6VwXpm',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SHDW',
    baseMint: 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SHDW/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Shadow',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/genesysgo',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'genesysgo-shadow',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: 'Epg66q6wPQbNyyegh8ZiP3iAgway4XFPS99UU3fzMaee',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'LMDA',
    baseMint: 'LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'LMDA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Lamda',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/6y2eY-KEaBu4KbZLJwLCTQQUXhWVPKxjgdyW69DwI0o',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 100,
    enable: true,
  },
  {
    address: '8EkT2jzErNR7xnKkAYtWq98NF9hwiYHi9UVXtDSAtksQ',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'FLWR',
    baseMint: 'HDVR9Edy2o8uRdf5xFUDSGVLo556UjAaNR9BrwZ8QeMJ',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'FLWR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Flowers',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/3brG4EGxdYt30nQMI-T_wkaOXl0xJQfcERzNFJpzTV0',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 2,
    enable: true,
  },
  {
    address: 'v2VwSbCzfUyCgTPGVq9AFUZ9KTiY4nZQ7kLx4tFLga7',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RUFF',
    baseMint: 'AB8xcYVLAMJRyyBExiaXG4VaaKgfcFZU6bpRENWrPFtF',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'RUFF/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'RUFF',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://raw.githubusercontent.com/RuffSolana/Token/assets/ruff.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 2,
    enable: true,
  },
  {
    address: 'JzvJv1vQ8HRsxKapNaaTXEPKFgggqKTYg8u1GkgKDRt',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BBONK',
    baseMint: '8NFa3QXg3kKqvdmJNjUhzqwvAnUYA9F5R4ymyjJfHcVB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'BBONK/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Baby Bonk Inu',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://shdw-drive.genesysgo.net/HaxcwvZXYbcVZoPGRkkg6GpnEmTBMA96LW853g5mRbYR/logo.jpeg',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 2,
    enable: true,
  },
  {
    address: 'GRrKTLEdEBDQuk6fw9NM7smPwL7CVfUR1C1MpLPx4fM2',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'JELLY',
    baseMint: '9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'JELLY/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Jelly',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'EwwPfa3d7FrUAjWV8vTvgyQmGpSpy2yP9xD5bzoKQuk3',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'HONK',
    baseMint: '7BT9CJGkVryLH5jRdBzUAqa72Mz9z6sABwBnx7a6ttiY',
    quote: 'SOL',
    quoteMint: 'So11111111111111111111111111111111111111112',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 9,
    symbol: 'HONK/SOL',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Honk',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/ppQQB-NFUMkcL6Qhhf6eLk3ksKvAZd9MSdXM_44d9Rs',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'EzLtLpX89y5DahffwFDWHsGzq3Kg8Tx2YvNqb6cyLGQG',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'KDA',
    baseMint: 'CKUUGK5dxNTLy4kX4PSTnHTDYzMQFmkH3K2CCkCZPqKa',
    quote: 'USDT',
    quoteMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'KDA/USDT',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Karda',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/WTedCh0WBqgsiJCfX-2EW_3lC3iIg9Ump6_vPSExgks',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '65EeM1SLnR4M2bUv7KDRfcA4ftQydP6TU8hxVsfDvLS2',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RBN',
    baseMint: '9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'RBN/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Robin',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '7C5SVhrmVV4sNMaP8gJnM6xUTT2uMUPo6kTHfoWZuvWe',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BREAD',
    baseMint: '7A4DPNz5rUZhHrAeRQ9C5yPivmVVnedJfTayYwrxCi7i',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'BREAD/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Bread',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://shdw-drive.genesysgo.net/FrqjMBaQZmxBFzc6Rzt6iEJLBnZJZ2sU3ty2fpJBToaf/bread.png_small',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },

  {
    address: 'D4rdTUdezgo22AYMuM4Kxr9sdg9P9qAMTyY4iRmsjZto',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'WOOP',
    baseMint: 'A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 6,
    symbol: 'WOOP/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Woop',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'Gmjq4394AB5eVtL4ELTraDVgXAn62Mk4r58H3xW5aTAe',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SDOGE',
    baseMint: '8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 0,
    quoteTokenDecimals: 6,
    symbol: 'SDOGE/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'SDOGE',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'HE1nibjUq5cTi6Zer5679od58PDAjtPAjTetUuV5XMPn',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MOLA',
    baseMint: 'AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MOLA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'MOONLANA',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 99,
    enable: true,
  },
  {
    address: 'VyUfTXavzvjkrQV2WCWPb3C1av9ePTHzwnyeGPCvnKd',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SOLCAINE',
    baseMint: '352NB9VnRPTcsjRDQ2ntKrA3FWUsvxkF7rY55x96PUVj',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SOLCAINE/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'SOLCAINE',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/Amz0HzFtSbQH1FHQY8_xidw2b_IMxiakytuV4B-cYtw',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'J27aUz91eZzxQDhHGMhdvJAqs9W6BBq6yerW9fNU9zxc',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'CUSE',
    baseMint: 'ExJyKJL221vuXRFHGMPpHffzvFrpSRyRgcuRaeXXnKJF',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'CUSE/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'CuseTheJuice',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://shdw-drive.genesysgo.net/HNyGeUqiPu1upPr5c3pRmGWYXN4PPRoEQJcyoFkPs5zG/image.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '4UPoJpXXjs1xX7z4xuja4ctBYZhEVQ4HDS7Fk2pkVmrv',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'CRONK',
    baseMint: '767JRTMNHq2ovozu5Td7s1XJoacxBYhYuvXuXiazwbca',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 6,
    symbol: 'CRONK/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Cronk Inu',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://cdn.discordapp.com/attachments/899853584047431781/1067399823285821550/ccronk_with_background.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'CUk1MEGo9ivAj8DF1hrRt57B1AZst1tMupn7tD3ZAxp2',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'DBONK',
    baseMint: '8XYJLZqoAodvsHZQ7YZE2Dfdjt7mog4gKMqojm9Xgsvy',
    quote: 'SOL',
    quoteMint: 'So11111111111111111111111111111111111111112',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 9,
    symbol: 'DBONK/SOL',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'DBONK',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/baaRf0WQEM44FBCxo_K1Dt2O9RATlTwDbvAYSQgk5sY',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '4dunCkvfCPSWTrym6SheCmzyXXFYz5dMZ4saL46YL88d',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: '$DD',
    baseMint: '3XJe9kn4xqKpX94DB5oRz6LvLv3zfqVL59SQs7A19mcP',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: '$DD/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: '$DOODLE',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://doodledevils.weebly.com/uploads/1/4/4/5/144500268/published/45.png?1674659606',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '7pQRrZMrhj6U19pj2stssAYyQdL7htYj6SGNGxwMvB6T',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'CHILI',
    baseMint: 'GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'CHILI/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'CHILI',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://iili.io/H5O87JS.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
]

export const MARKETS_TO_STRING = JSON.stringify(_.sortBy(DEXLAB_MARKETS, (a) => a.priority))
