import { HashRouter, Route, Redirect } from 'react-router-dom'
import OpenOrdersPage from './pages/OpenOrdersPage'
import MyIdoListPage from './pages/MyIdoListPage'
import React from 'react'
import { getTradePageUrl } from './utils/dex-markets'
import BalancesPage from './pages/BalancesPage'
import ConvertPage from './pages/ConvertPage'
import BasicLayout from './componentsv2/BasicLayout'
import DexExtraMarketPage from './pages/mintinglab/DexExtraMarketPage'
import ListNewMarketPage from './pages/ListNewMarketPage'
import DexAddExistingMarketPage from './pages/mintinglab/DexAddExistingMarketPage'
import TradeV2RoutePage from './pages/v2/TradeV2RoutePage'
import CommonErrorPage from './pages/CommomErrorPage'
import SwapPage from './pages/SwapPage'

export function Routes() {
  return (
    <HashRouter basename={'/'}>
      <Route exact path="/">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/minting-lab/mintinglab">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-01">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-02">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-03">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-04">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-05">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-06">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/help-07">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/stake">
        <Redirect to={getTradePageUrl()} />
      </Route>
      <Route exact path="/market/:marketAddress" component={TradeV2PageContents} />
      <Route exact path="/swap" component={OrderBookBaseSwapContents} />
      <Route exact path="/v2/orders/:marketAddress" component={TradeV2PageContents} />
      <Route exact path="/v2/basic" component={BasicTradePageContents} />
      <Route exact path="/v2/exchange" component={BasicTradePageContents} />
      <Route exact path="/v2/my/orders" component={OpenOrdersPageContents} />
      <Route exact path="/v2/my/balances" component={BalancesPageContents} />
      <Route exact path="/v2/my/ido" component={MyIdoPageContents} />
      <Route
        path="/minting-lab/markets"
        component={() => {
          window.location.href = 'https://openbook-tools.dexlab.space'
          return null
        }}
      />
      <Route
        path="/minting-lab/new-markets"
        component={() => {
          window.location.href = 'https://openbook-tools.dexlab.space'
          return null
        }}
      />
      <Route
        path="/minting-lab/add-markets"
        component={() => {
          window.location.href = 'https://openbook-tools.dexlab.space'
          return null
        }}
      />
      {/* <Route exact path="/minting-lab/markets">
        <BasicLayout>
          <DexExtraMarketPage />
        </BasicLayout>
      </Route> */}
      {/* <Route exact path="/minting-lab/new-markets">
        <BasicLayout>
          <ListNewMarketPage />
        </BasicLayout>
      </Route>
      <Route exact path="/minting-lab/add-markets">
        <BasicLayout>
          <DexAddExistingMarketPage />
        </BasicLayout>
      </Route> */}
      <Route exact path="/error" component={CommonContents} />
    </HashRouter>
  )
}

function CommonContents() {
  return (
    <BasicLayout>
      <CommonErrorPage />
    </BasicLayout>
  )
}

function TradeV2PageContents() {
  return <TradeV2RoutePage />
}

function MyIdoPageContents() {
  return (
    <BasicLayout>
      <MyIdoListPage />
    </BasicLayout>
  )
}

function OpenOrdersPageContents() {
  return (
    <BasicLayout>
      <OpenOrdersPage />
    </BasicLayout>
  )
}

function BalancesPageContents() {
  return (
    <BasicLayout>
      <BalancesPage />
    </BasicLayout>
  )
}

function BasicTradePageContents() {
  return (
    <BasicLayout>
      <ConvertPage />
    </BasicLayout>
  )
}

function OrderBookBaseSwapContents() {
  return (
    <BasicLayout>
      <SwapPage />
    </BasicLayout>
  )
}
