/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import logo from '../../assets/dexlab_symbol.svg'

const antIcon = <LoadingOutlined style={{ color: '#FFFFFF', fontSize: 30 }} spin />

export default function TradeV2RoutePage() {
  const { marketAddress } = useParams()

  useEffect(() => {
    if (marketAddress) {
      if (marketAddress === 'DYfigimKWc5VhavR4moPBibx9sMcWYVSjVdWvPztBPTa') {
        // DXL
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z`
      } else if (marketAddress === 'DFdcGFcAVWZ3UgVgpbBChFKen3URZdZ8dmju8GTXQgCE') {
        // RLB
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a`
      } else if (marketAddress === '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT') {
        // SOL
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=So11111111111111111111111111111111111111112`
      } else {
        window.location.href = `https://www.dexlab.space/swap?id=${marketAddress}`
      }
    } else {
      window.location.href = `https://www.dexlab.space/swap`
    }
  }, [marketAddress])

  return <LoadingView />
}

function LoadingView() {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <img style={{ width: '200px', height: '60px' }} src={logo} alt="Dexlab OpenBook DEX" />
      <div>The best DEX platform on SOLANA.</div>
      <div style={{ marginTop: '30px' }}>
        <Spin indicator={antIcon} />
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          <span>
            <a style={{ color: '#FFFFFF' }} href="https://www.dexlab.space" target="_blank" rel="noopener noreferrer">
              Dexlab
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
          <span>
            <a
              style={{ color: '#FFFFFF' }}
              href="https://stats.uptimerobot.com/8OZwzIjnn1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Service Status
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
          <span>
            <a
              style={{ color: '#FFFFFF' }}
              href="https://minting.dexlab.space"
              target="_blank"
              rel="noopener noreferrer"
            >
              Minting Lab
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
        </div>
      </div>
    </div>
  )
}
